<script setup lang="ts">
import useCmd from "@/composables/useCmd";
import { ButtonNormal, ModalCreate, types } from "lorga-ui";
import { computed, toRefs } from "vue";
import { useUsers } from "../api/useUsers";

const props = defineProps<{
  query: () => void;
  fahrerName: string;
  fahrerTyp: string;
  fahrerId: string;
  fahrerEmail: string | null;
  fahrerPk: number | null;
  fahrerUser: string | null;
  fahrerUserId: string | null;
}>();
const { query } = toRefs(props);

const { commandModalOpen, commandRequest } = useCmd(query);

const { usersForm } = useUsers(commandModalOpen);

const fields = computed<types.FormField[]>(
  () =>
    [
      {
        label: "PK Nummer",
        name: "pk_nummer",
        type: "number",
        required: true,
      },
      {
        label: "Anzeige Name",
        name: "anzeige_name",
        type: "text",
        required: true,
      },
      {
        label: "E-Mail",
        name: "email",
        type: "text",
        required: true,
      },
      {
        label: "Typ",
        name: "typ",
        type: "select",
        required: true,
        options: [
          { name: "Intern", value: "intern" },
          { name: "Extern", value: "extern" },
        ],
      },
      {
        label: "User",
        name: "user_id",
        type: "select",
        required: false,
        options: usersForm.value,
      },
    ] as types.FormField[],
);
</script>

<template>
  <ButtonNormal kind="action" @click="commandModalOpen = true">
    <ModalCreate
      v-model="commandModalOpen"
      submit="Speichern"
      title="Fahrer Bearbeiten"
      :data="{
        action: 'fahrer.bearbeiten',
        anzeige_name: fahrerName,
        fahrer_id: fahrerId,
        typ: fahrerTyp,
        pk_nummer: fahrerPk,
        user_id: fahrerUserId,
        email: fahrerEmail,
      }"
      :fields="fields"
      :request="commandRequest"
    />
    Bearbeiten
  </ButtonNormal>
</template>
