<script setup lang="ts">
import useAuftraege from "../api/useAuftraege";
import AuftragItem from "../components/AuftragItem.vue";

const { auftraege } = useAuftraege();
</script>

<template>
  <div class="p-5 px-6 bg-gray-500">
    <div class="flex flex-col max-w-md gap-5">
      <div v-for="a in auftraege" :key="a.id">
        <AuftragItem :auftrag="a" />
      </div>
    </div>
  </div>
</template>
