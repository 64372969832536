/* eslint-disable no-unused-vars */
export enum TYP {
  L = "Arbeitszeit LKW",
  K = "Arbeitszeit Kran",
  G = "Arbeitszeit Großgerät",
  S = "Arbeitszeit Shuttle / BF3 Leerfahrt",
  B = "Beifahrer / Büro",
  H = "Reise- / Bereitschaftszeit",
  W = "Wartezeit",
  P = "Pause",
  R = "Ruhezeit",
}
/* eslint-enable no-unused-vars */

export const TYPEN_FORM = Object.entries(TYP).map(([key, value]) => ({
  value: key,
  name: value,
}));
