import useClient from "@/api/client";
import useQuery from "@/composables/useQuery";
import { useValidation } from "@/composables/useValidation";
import { computed, Ref, ref, watch } from "vue";
import { z } from "zod";

const ZUser = z.object({
  id: z.string(),
  email: z.string().email(),
  name: z.string(),
  groups: z.array(z.string()),
});

type IUser = z.infer<typeof ZUser>;

export const useUsers = (shouldQuery?: Ref<boolean>) => {
  const users = ref<IUser[]>();
  const client = useClient();
  const { request } = client.get("/query/users/");
  useValidation(users, z.array(ZUser).optional());

  const query = useQuery(request, users);

  if (shouldQuery === undefined) {
    query();
  } else {
    watch(shouldQuery, (newValue) => {
      if (newValue) {
        query();
      }
    });
  }

  const usersForm = computed(() => {
    return users.value?.map((user) => {
      return {
        value: user.id,
        name: user.name,
      };
    });
  });

  return {
    query,
    users,
    usersForm,
  };
};
