/* eslint-disable no-unused-vars */
export enum Group {
  DISPOSITION = "disposition",
  MANAGEMENT = "management",
  BUCHHALTUNG = "buchhaltung",
  FAHRER = "fahrer",
  ABRECHNUNG = "abrechnung",
}
/* eslint-enable no-unused-vars */

const GROUPS = Object.values(Group);

export const GROUPS_FORM = GROUPS.map((group) => ({
  value: group,
  name: group.charAt(0).toUpperCase() + group.slice(1),
}));
