import useClient from "@/api/client";
import useGet from "@/composables/useGet";
import { useValidation } from "@/composables/useValidation";
import { ref } from "vue";
import { z } from "zod";

const ZFahrerSingle = z.object({
  id: z.string(),
  anzeige_name: z.string(),
  email: z.string().nullable(),
  user_id: z.string().nullable(),
  user_name: z.string().nullable(),
});

const ZFahrer = z.array(ZFahrerSingle).optional();

type IFahrer = z.infer<typeof ZFahrer>;

export const useFahrer = () => {
  const fahrer = ref<IFahrer[]>();

  const client = useClient();
  const { request, setQueryParam, queryParams } = client.get("/query/fahrer/");

  useValidation(fahrer, ZFahrer);
  const query = useGet(request, fahrer, queryParams);

  return {
    query,
    fahrer,
    setQueryParam,
    queryParams,
  };
};
