<script lang="ts" setup>
import { formatDate } from "@/utils/date";
import { computed, toRefs } from "vue";

const props = defineProps<{
  datum: string | null;
  bis?: string;
}>();
const { datum, bis } = toRefs(props);

const start = computed(() =>
  datum.value ? formatDate(datum.value) : "Unbekannte Zeit",
);
const ende = computed(() => (bis.value ? formatDate(bis.value) : null));
</script>

<template>
  <li class="flex items-baseline">
    <span class="block w-24 text-gray-800">
      {{ start }}
      <template v-if="ende && start !== ende">
        <br />
        {{ ende }}
      </template>
    </span>
    <div class="w-full px-2 py-1 text-gray-800 border border-gray-700 rounded">
      <slot></slot>
    </div>
  </li>
</template>
