<script setup lang="ts">
import useCmd from "@/composables/useCmd";
import { ButtonNormal, ModalUpdate, types } from "lorga-ui";
import { computed } from "vue";

const props = defineProps<{
  query: () => void;
  belegId: string;
  beschreibung: string;
  betrag: number;
  datum: string;
}>();

const { commandModalOpen, commandRequest } = useCmd(props.query);

const fields = computed<types.FormField[]>(() => [
  {
    label: "Betrag",
    name: "betrag",
    type: "number",
    required: true,
  },
  {
    label: "Beschreibung",
    name: "beschreibung",
    type: "text",
    required: true,
  },
  {
    label: "Datum",
    name: "datum",
    type: "date",
    required: true,
  },
]);
</script>

<template>
  <ButtonNormal kind="action" @click="commandModalOpen = true">
    <ModalUpdate
      v-model="commandModalOpen"
      submit="Bearbeiten"
      title="Beleg Bearbeiten"
      :data="{
        action: 'beleg.bearbeiten',
        beleg_id: belegId,
        betrag: betrag,
        beschreibung: beschreibung,
        datum: datum,
      }"
      :fields="fields"
      :request="commandRequest"
    />
    Bearbeiten
  </ButtonNormal>
</template>
