<script setup lang="ts"></script>

<template>
  <div
    class="px-5 py-3 bg-gray-100 [&>hr]:mb-4 [&>hr]:mt-3 [&>hr]:border-t-2 [&>hr]:border-gray-400"
  >
    <div class="flex items-baseline justify-between">
      <div>
        <slot name="heading"></slot>
      </div>
      <div>
        <slot name="actions"></slot>
      </div>
    </div>
    <hr />
    <slot></slot>
  </div>
</template>
