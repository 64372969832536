<script setup lang="ts">
import { useRoute } from "vue-router";
import FahrzeitEintragen from "../actions/FahrzeitEintragen.vue";
import BelegEinreichen from "../actions/BelegEinreichen.vue";
import useAuftrag from "../api/useAuftrag";
import { CircleLoader } from "lorga-ui";
import ZeitEintrag from "../components/ZeitEintrag.vue";
import WhiteBox from "../components/WhiteBox.vue";
import DispositionsAuftragTop from "@/features/disposition/components/DispositionsAuftragTop.vue";
import DispositionsAuftragFahrzeug from "@/features/disposition/components/DispositionsAuftragFahrzeug.vue";
import ZeitBox from "../components/ZeitBox.vue";
import BelegEintrag from "../components/BelegEintrag.vue";

const route = useRoute();

const { auftrag, blatt, belege, query, zeiten, nextStartzeit } = useAuftrag(
  route.params.auftragId as string,
);
</script>

<template>
  <div class="p-5 px-6 bg-gray-500">
    <div v-if="!auftrag || blatt === undefined || belege === undefined">
      <CircleLoader class="text-white" />
    </div>
    <div v-else>
      <div class="space-y-5">
        <WhiteBox class="max-w-2xl">
          <template #heading>
            <h1 class="text-xl font-bold">Auftrag</h1>
          </template>
          <DispositionsAuftragTop
            :abholung="auftrag.abholung"
            :anlieferung="auftrag.anlieferung"
          />
          <DispositionsAuftragFahrzeug :fahrzeug="auftrag.fahrzeug" />
        </WhiteBox>
        <WhiteBox class="max-w-xl">
          <template #heading>
            <h2 class="text-lg font-bold">Fahrzeiten</h2>
          </template>
          <template #actions>
            <FahrzeitEintragen
              :query="query"
              :auftrag-id="auftrag.id"
              :startzeit="nextStartzeit"
            />
          </template>
          <ul class="space-y-3">
            <ZeitBox :datum="auftrag.abholung.datum">Geplante Abholung</ZeitBox>
            <template v-for="zeit in zeiten" :key="zeit.id">
              <ZeitEintrag
                :zeit="zeit"
                :query="query"
                :auftrag-id="auftrag.id"
              />
            </template>
            <li class="flex justify-end">
              <FahrzeitEintragen
                :query="query"
                :auftrag-id="auftrag.id"
                :startzeit="nextStartzeit"
              />
            </li>
          </ul>
        </WhiteBox>
        <WhiteBox class="max-w-xl">
          <template #heading>
            <h2 class="text-lg font-bold">Belege</h2>
          </template>
          <template #actions>
            <BelegEinreichen :query="query" :auftrag-id="auftrag.id" />
          </template>
          <ul class="space-y-3">
            <template v-for="beleg in belege" :key="beleg.id">
              <BelegEintrag
                :beleg="beleg"
                :query="query"
                :auftrag-id="auftrag.id"
              />
            </template>
          </ul>
        </WhiteBox>
      </div>
    </div>
  </div>
</template>
