import { routes as dispositionRoutes } from "@/features/disposition/routes";
import { routes as managementRoutes } from "@/features/management/routes";
import testingRoutes from "@/features/testing/routes";
import { routes as usersRoutes } from "@/features/auth/routes";
import { RouteRecordRaw } from "vue-router";
import IndexView from "@/IndexView.vue";
import PassThrough from "@/components/PassThrough.vue";
import ErrorPage from "@/components/ErrorPage.vue";
import { routes as abrechnungRoutes } from "@/features/abrechnung/routes";
import { routes as sonstigesRoutes } from "@/features/sonstiges/routes";
import { routes as accountRoutes } from "@/features/auth/routes";
import { routes as fahrerRoutes } from "@/features/fahrer/routes";

// attention: here please add a slash in front of the paths as they are root paths
// and vue would complain if they are missing. i know vue router is a little bit weird.
export default [
  { path: "/", component: IndexView, name: "index" },
  { path: "/disposition", component: PassThrough, children: dispositionRoutes },
  { path: "/abrechnung", component: PassThrough, children: abrechnungRoutes },
  { path: "/users", component: PassThrough, children: usersRoutes },
  { path: "/management", component: PassThrough, children: managementRoutes },
  { path: "/sonstiges", component: PassThrough, children: sonstigesRoutes },
  { path: "/account", component: PassThrough, children: accountRoutes },
  { path: "/fahrer", component: PassThrough, children: fahrerRoutes },
  { path: "/testing", component: PassThrough, children: testingRoutes },
  {
    path: "/error",
    name: "error",
    component: ErrorPage,
  },
] as RouteRecordRaw[];
