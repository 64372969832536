import useClient from "@/api/client";
import useGet from "@/composables/useGet";
import { useValidation } from "@/composables/useValidation";
import { addToDateString } from "@/utils/date";
import { computed, ref } from "vue";
import { z } from "zod";

const ZPunkt = z.object({
  name: z.string(),
  datum: z.string().nullable(),
});

const ZFahrzeug = z.object({
  kennzeichen: z.string(),
  fahrzeugtyp: z.string(),
  fahrer_namen: z.array(z.string()),
  fahrgestellnummer: z.string(),
});

const ZAuftrag = z.object({
  id: z.string(),
  abholung: ZPunkt,
  anlieferung: ZPunkt,
  fahrzeug: ZFahrzeug,
});

const ZLandPunkt = z.object({
  land: z.string(),
  zeit: z.string(),
});

const ZZeit = z.object({
  id: z.string(),
  bemerkung: z.string(),
  typ: z.string(),
  start: ZLandPunkt,
  ende: ZLandPunkt,
  typ_key: z.string(),
  error: z.string(),
  stunden: z.string(),
});

const ZBlatt = z.object({
  zeiten: z.array(ZZeit),
});

const ZBeleg = z.object({
  id: z.string(),
  betrag: z.number(),
  beschreibung: z.string(),
  datum: z.string(),
});

const ZOutput = z.object({
  auftrag: ZAuftrag,
  tageskontrollblatt: ZBlatt.nullable(),
  belege: z.array(ZBeleg),
});

export type IAuftrag = z.infer<typeof ZAuftrag>;
export type IOutput = z.infer<typeof ZOutput>;
export type IZeit = z.infer<typeof ZZeit>;
export type IBeleg = z.infer<typeof ZBeleg>;

const useAuftraege = (id: string) => {
  const client = useClient();
  const data = ref<IOutput>();

  const { request, urlParams } = client.get("/query/fahrer_auftrag/{}/");
  urlParams.value = id;
  const query = useGet(request, data);

  useValidation(data, ZOutput.optional());

  const auftrag = computed(() => data.value?.auftrag);
  const blatt = computed(() => data.value?.tageskontrollblatt);
  const belege = computed(() => data.value?.belege);
  const zeiten = computed(() => (blatt.value ? blatt.value.zeiten : []));

  const nextStartzeit = computed(() => {
    if (zeiten.value.length === 0) return auftrag.value?.abholung.datum;
    return addToDateString(zeiten.value[zeiten.value.length - 1].ende.zeit, {
      minutes: 1,
    });
  });

  return {
    query,
    auftrag,
    zeiten,
    blatt,
    belege,
    nextStartzeit,
  };
};

export default useAuftraege;
