export const formatDate = (
  dateString: string | null | undefined,
  dateStyle: Intl.DateTimeFormatOptions["dateStyle"] = "short",
  timeStyle: Intl.DateTimeFormatOptions["timeStyle"] = undefined,
) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  // return only the date if the date string has no time
  if (dateString.length === 10)
    return new Intl.DateTimeFormat("de-de", {
      dateStyle: dateStyle,
      timeStyle: timeStyle,
    })
      .format(date)
      .split(",")[0];
  // then specify how you want your dates to be formatted
  return new Intl.DateTimeFormat("de-de", {
    dateStyle: dateStyle,
    timeStyle: timeStyle,
  }).format(date);
};

export const getWeekNumber = (
  dateString: string | null | undefined,
): string => {
  if (!dateString) return "";
  const tempDate = new Date(dateString);
  tempDate.setHours(0, 0, 0, 0);
  tempDate.setDate(tempDate.getDate() + 3 - ((tempDate.getDay() + 6) % 7));
  const week1 = new Date(tempDate.getFullYear(), 0, 4);
  const weekNumber = (
    1 +
    Math.round(
      ((tempDate.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7,
    )
  ).toString();
  if (weekNumber.length == 1) return `0${weekNumber}`;
  return weekNumber;
};

export const formatTime = (dateString: string | null | undefined) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("de-de", {
    timeStyle: "short",
  }).format(date);
};

export interface FormattedDate {
  day: string;
  month: string;
  shortMonth: string;
  year: string;
  formatted: string;
  groupDate: string;
}

export const formatDateToObject = (dateString: string): FormattedDate => {
  const date = new Date(dateString);
  return {
    day: date.getDate().toString(),
    month: date.getMonth().toString(),
    shortMonth: date.toLocaleDateString("default", { month: "short" }),
    year: date.getFullYear().toString(),
    formatted: formatDate(date.toISOString()),
    groupDate: date.toLocaleDateString(),
  };
};

export const datesDaysDifferent = (
  dateString1: string | null,
  dateString2: string | null,
): boolean => {
  if (!dateString1 && !dateString2) return false;
  if (!dateString1 || !dateString2) return true;
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);
  return date1.toDateString() !== date2.toDateString();
};

export const days = [
  "Sonntag",
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
];

function _getMondayDate(): Date {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
  const monday = new Date(today.setDate(diff));
  return monday;
}

export function getMondayDate(): string {
  const monday = _getMondayDate();
  const isoMonday = monday.toISOString().slice(0, 10);
  return isoMonday;
}

export function getFirstOfMonthDate(): string {
  const monday = _getMondayDate();
  monday.setDate(1);
  const firstOfMonth = monday.toISOString().slice(0, 10);
  return firstOfMonth;
}

export function getThisWeek() {
  const monday = _getMondayDate();
  const year = monday.getFullYear();
  const week = getWeekNumber(monday.toISOString());
  return `${year}-W${week}`;
}

export function getNextWeek() {
  const monday = _getMondayDate();
  monday.setDate(monday.getDate() + 7);
  const year = monday.getFullYear();
  const week = getWeekNumber(monday.toISOString());
  return `${year}-W${week}`;
}

export function nowAsString() {
  return new Date().toISOString();
}

export function addToDateString(dateString: string, add: { minutes: number }) {
  const date = new Date(dateString);
  const timezoneOffset = date.getTimezoneOffset();
  date.setMinutes(date.getMinutes() + add.minutes - timezoneOffset);
  return date.toISOString().slice(0, 16);
}
