import useClient from "@/api/client";
import useGet from "@/composables/useGet";
import { useValidation } from "@/composables/useValidation";
import { ref } from "vue";
import { z } from "zod";

const ZPunkt = z.object({
  name: z.string(),
  datum: z.string().nullable(),
});

const ZFahrzeug = z.object({
  kennzeichen: z.string(),
  fahrzeugtyp: z.string(),
  fahrer_namen: z.array(z.string()),
});

const ZAuftrag = z.object({
  id: z.string(),
  abholung: ZPunkt,
  anlieferung: ZPunkt,
  fahrzeug: ZFahrzeug,
});

export type IAuftrag = z.infer<typeof ZAuftrag>;

const useAuftraege = () => {
  const client = useClient();
  const auftraege = ref<IAuftrag[]>();

  const { request } = client.get("/query/fahrer_auftraege/");
  const query = useGet(request, auftraege);

  useValidation(auftraege, z.array(ZAuftrag).optional());

  return {
    query,
    auftraege,
  };
};

export default useAuftraege;
