<script setup lang="ts">
import useCmd from "@/composables/useCmd";
import { ButtonNormal, ModalCreate, types } from "lorga-ui";
import { computed } from "vue";

const props = defineProps<{
  query: () => void;
  auftragId: string;
}>();

const { commandModalOpen, commandRequest } = useCmd(props.query);

const fields = computed<types.FormField[]>(() => [
  {
    label: "Betrag",
    name: "betrag",
    type: "number",
    required: true,
  },
  {
    label: "Beschreibung",
    name: "beschreibung",
    type: "text",
    required: true,
  },
  {
    label: "Datum",
    name: "datum",
    type: "date",
    required: true,
  },
]);
</script>

<template>
  <ButtonNormal kind="action" @click="commandModalOpen = true">
    <ModalCreate
      v-model="commandModalOpen"
      submit="Einreichen"
      title="Beleg Einreichen"
      :data="{
        action: 'beleg.erstellen',
        auftrag_id: auftragId,
        betrag: 0,
        beschreibung: '',
      }"
      :fields="fields"
      :request="commandRequest"
    />
    + Beleg Einreichen
  </ButtonNormal>
</template>
