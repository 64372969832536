import FahrerAuftraege from "@/features/fahrer/views/FahrerAuftraege.vue";
import FahrerAuftrag from "@/features/fahrer/views/FahrerAuftrag.vue";

// attention: do not add a slash in front of the paths as this will break the routing.
// For example the router will see it as a root path like '/dashboard' instead of '/whatever/dashboard'.
export const routes = [
  { path: "auftraege", component: FahrerAuftraege, name: "fahrer-auftraege" },
  {
    path: "auftrag/:auftragId",
    component: FahrerAuftrag,
    name: "fahrer-auftrag",
  },
];
