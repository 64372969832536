<script setup lang="ts">
import useCmd from "@/composables/useCmd";
import { ButtonNormal, ModalUpdate, types } from "lorga-ui";
import { computed, toRefs } from "vue";
import { TYPEN_FORM } from "../const/fahrzeiten";
import { IZeit } from "../api/useAuftrag";

const props = defineProps<{
  query: () => void;
  auftragId: string;
  zeit: IZeit;
}>();
const { query } = toRefs(props);

const { commandModalOpen, commandRequest } = useCmd(query);

const fields = computed<types.FormField[]>(() => [
  {
    label: "Typ",
    name: "typ",
    type: "select",
    options: TYPEN_FORM,
    required: true,
  },
  {
    label: "Startland",
    name: "startland",
    type: "text",
    required: true,
  },
  {
    label: "Endland",
    name: "endland",
    type: "text",
    required: true,
  },
  {
    label: "Startzeit",
    name: "startzeit",
    type: "datetime-local",
    required: true,
  },
  {
    label: "Endzeit",
    name: "endzeit",
    type: "datetime-local",
    required: true,
  },
  {
    label: "Bemerkung",
    name: "bemerkung",
    type: "text",
    required: false,
  },
]);
</script>

<template>
  <ButtonNormal kind="action" @click="commandModalOpen = true">
    <ModalUpdate
      v-model="commandModalOpen"
      submit="Speichern"
      title="Fahrzeit Bearbeiten"
      :data="{
        action: 'tageskontrollblatt.fahrzeit_bearbeiten',
        auftrag_id: auftragId,
        fahrzeit_id: zeit.id,
        typ: zeit.typ_key,
        startland: zeit.start.land,
        endland: zeit.ende.land,
        startzeit: zeit.start.zeit,
        endzeit: zeit.ende.zeit,
        bemerkung: zeit.bemerkung,
      }"
      :fields="fields"
      :request="commandRequest"
    />
    Bearbeiten
  </ButtonNormal>
</template>
