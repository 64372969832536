<script setup lang="ts">
import useCmd from "@/composables/useCmd";
import { ButtonNormal, ModalCreate, types } from "lorga-ui";
import { computed } from "vue";
import { TYPEN_FORM } from "../const/fahrzeiten";

const props = defineProps<{
  query: () => void;
  auftragId: string;
  startzeit: string | null | undefined;
}>();

const { commandModalOpen, commandRequest } = useCmd(props.query);

const fields = computed<types.FormField[]>(() => [
  {
    label: "Typ",
    name: "typ",
    type: "select",
    options: TYPEN_FORM,
    required: true,
  },
  {
    label: "Startland",
    name: "startland",
    type: "text",
    required: true,
  },
  {
    label: "Endland",
    name: "endland",
    type: "text",
    required: true,
  },
  {
    label: "Startzeit",
    name: "startzeit",
    type: "datetime-local",
    required: true,
  },
  {
    label: "Endzeit",
    name: "endzeit",
    type: "datetime-local",
    required: true,
  },
  {
    label: "Bemerkung",
    name: "bemerkung",
    type: "text",
    required: false,
  },
]);
</script>

<template>
  <ButtonNormal kind="action" @click="commandModalOpen = true">
    <ModalCreate
      v-model="commandModalOpen"
      submit="Eintragen"
      title="Fahrzeit Eintragen"
      :data="{
        action: 'tageskontrollblatt.fahrzeit_eintragen',
        auftrag_id: auftragId,
        startland: 'Deutschland',
        endland: 'Deutschland',
        startzeit: startzeit,
        endzeit: startzeit,
      }"
      :fields="fields"
      :request="commandRequest"
    />
    + Zeit Eintragen
  </ButtonNormal>
</template>
